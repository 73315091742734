import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FaPhone, FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { PiEnvelopeSimpleBold } from "react-icons/pi";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { DataContext } from "../../context/DataContext";
import KanbanStatuscolumSkeleton from "../KanbanStatuscolumSkeleton/KanbanStatuscolumSkeleton";

const statusArray = ["Initial Review", "Additional Documents", "Golden Data", "The Great Takeover", "Pre-Underwriting", "Pre-Offer", "Underwriting", "Extra docs ob", "Awaiting Offer", "Offer", "Contract Sent", "Awaiting Funding", "Funded This Month", "Last Chance", "Cancelled", "Cancelled for ever"];

const StatusColumn = ({ status, setLoanList, loanList, metaData, setMetaData, reduceTotalLoansNumber, increaseTotalLoansNumber }) => {
    const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
    const { searchRequestValue, setUploading } = useContext(DataContext);
    const { user, adminData } = useContext(AuthContext);
    const [dataLoading, setDataLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [thisStatusData, setThisStatusData] = useState([]);
    const showOfferAmountStatus = ["Offer", "Contract Sent", "Awaiting Funding", "Funded This Month", "Funded"];

    // test
    const [localTotalAmountStat, setLocalTotalAmountStat] = useState({});

    const dragStartHandler = (e, i) => {
        e.dataTransfer.setData("id", i?._id);
        e.dataTransfer.setData("status", i?.status);
        e.dataTransfer.setData("lender", i?.lender);
        reduceTotalLoansNumber(status);

        if (["Offer", "Contract Sent", "Awaiting Funding", "Funded This Month", "Funded"].includes(status)) {
            setLocalTotalAmountStat((prev) => ({
                ...prev,
                totalRequiredAmount: prev.totalRequiredAmount - parseInt(loanList.find((item) => item._id === i._id && item.status === i?.status)["offer-repayment"] || 0),
            }));
        } else {
            console.log(i);

            setLocalTotalAmountStat((prev) => ({
                ...prev,
                totalRequiredAmount: prev.totalRequiredAmount - parseInt(loanList.find((item) => item._id === i._id && item.status === i?.status)["required-amount"] || 0),
            }));
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const dragDropHandler = async (e) => {
        const dealId = e.dataTransfer.getData("id");
        const lender = e.dataTransfer.getData("lender");
        const prevStatus = e.dataTransfer.getData("status");

        console.log("prev:", statusArray.indexOf(prevStatus), "new:", statusArray.indexOf(status));

        if (adminData?.role !== "super-admin" && user?.email !== "millie@sedgefunding.co.uk" && user?.email !== "smit@readysteadyfinance.co.uk" && user?.email !== "mark@sedgefunding.co.uk" && user?.email !== "don@sedgefunding.co.uk" && statusArray.indexOf(prevStatus) > statusArray.indexOf(status)) {
            return;
        }

        // if(user?.email === 'mark@sedgefunding.co.uk' || user?.email === 'don@sedgefunding.co.uk') {
        //     if(statusArray.indexOf(prevStatus) > statusArray.indexOf(status)){
        //         if(statusArray.indexOf(prevStatus) > 4){
        //             return;
        //         }
        //         else{}
        //     }else{}
        // }

        setUploading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/update-status/${dealId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    email: user.email,
                    status: status,
                    lender: lender,
                }),
            });

            if (status === "Funded This Month") {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/update-funding-manager-add-to-book`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ _id: dealId, lendername: lender }),
                    });

                    const data = response.massage;

                    if (response.ok) {
                        console.log("Funding history updated successfully!");
                    } else {
                        console.log(`Failed to update: ${data}`);
                    }
                } catch (error) {
                    // alert('An error occurred. Please try again.');
                    console.log(error);
                }
            }

            if (response.ok) {
                try {
                    const statsResponse = await axios.post(
                        `${serverURL}/api/v3/loan-requests-stats/${status}`,
                        {
                            email: user.email,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    );

                    const statsData = statsResponse.data;
                    setLocalTotalAmountStat(statsData);
                } catch (error) {
                    console.error("Error fetching loan stats:", error);
                }

                await setLoanList((prevList) => prevList.map((item) => (item._id === dealId && item?.lender === lender ? { ...item, status } : item)));
                await increaseTotalLoansNumber(status);
                setUploading(false);
            } else {
                console.error("Error:", response.status);
                setUploading(false);
            }
        } catch (error) {
            console.error("Error:", error);
            setUploading(false);
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case "Initial Review":
                return "status-initial-review";
            case "Additional Documents":
                return "status-additional-documents";
            case "Golden Data":
                return "status-golden-data";
            case "The Great Takeover":
                return "status-the-great-takeover";
            case "Pre-Underwriting":
                return "status-pre-underwriting";
            case "Pre-Offer":
                return "status-pre-offer";
            case "Underwriting":
                return "status-underwriting";
            case "Extra docs ob":
                return "status-extra-docs-ob";
            case "Awaiting Offer":
                return "status-awaiting-offer";
            case "Offer":
                return "status-offer";
            case "Contract Sent":
                return "status-contract-sent";
            case "Awaiting Funding":
                return "status-awaiting-funding";
            case "Funded This Month":
                return "status-funded-this-month";
            case "Funded":
                return "status-funded";
            case "Last Chance":
                return "last-chance";
            case "Cancelled":
                return "status-cancelled";
            case "Cancelled for ever":
                return "status-cancelled-for-ever";
            default:
                return ""; // Default class if status is not found
        }
    };

    const statusClass = getStatusClass(status);

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
    };

    // test
    const loaderRef = useRef(null);
    const fetchMetaData = async (isSearch = false) => {
        try {
            const statsResponse = await axios.post(
                `${serverURL}/api/v3/loan-requests-stats/${status}`,
                {
                    email: user.email,
                    ...(isSearch && { search: searchRequestValue }),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            const statsData = statsResponse.data;
            setLocalTotalAmountStat(statsData);
            setMetaData((prevData) => [...prevData, { status, totalLoans: statsData.total, totalRequiredAmount: statsData.totalRequiredAmount }]);
        } catch (error) {
            console.error("Error fetching loan stats:", error);
        }
    };

    const fetchData = (pageToLoad = 1, isSearch = false) => {
        try {
            const apiURL = `${serverURL}/api/v3/deal-list/${status}/${pageToLoad}`;

            const requestBody = {
                email: user.email,
                ...(isSearch && { search: searchRequestValue }), // Add search query only if it's a search request
            };

            axios
                .post(apiURL, requestBody, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    const data = response.data;

                    if (Array.isArray(data)) {
                        setThisStatusData(data);

                        setLoanList((prevData) => {
                            const newData = data.filter((newItem) => !prevData.some((prevItem) => JSON.stringify(prevItem) === JSON.stringify(newItem)));

                            return [...prevData, ...newData];
                        });
                    } else {
                        console.error("Response data is not an array:", data);
                    }
                    setDataLoading(false);
                })
                .catch((error) => {
                    setDataLoading(false);
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            setDataLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const fetchMoreData = async () => {
        try {
            const nextPage = page + 1;
            const apiURL = `${serverURL}/api/v3/deal-list/${status}/${nextPage}`;

            const requestBody = {
                email: user.email,
                ...(Object.keys(searchRequestValue).length !== 0 && { search: searchRequestValue }), // Include search query if present
            };

            const response = await axios.post(apiURL, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            const newData = response.data;
            setLoanList((prevLoanList) => [...prevLoanList, ...newData]);
            setPage(nextPage);
        } catch (error) {
            console.error("Error fetching more data:", error);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData(); // Assuming fetchMoreData function is implemented to fetch more data
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [fetchMoreData]);

    useEffect(() => {
        if (Object.keys(searchRequestValue).length !== 0) {
            setLoanList([]); // Clear previous results on a new search
            fetchData(1, true); // Fetch data with search query starting from page 1
            setMetaData([]);
            fetchMetaData(true);
        }
    }, [searchRequestValue]);

    useEffect(() => {
        if (Object.keys(searchRequestValue).length === 0) {
            fetchData(); // Fetch data without search when search is cleared
        }
        fetchMetaData(); // Fetch other metadata if needed
    }, []);

    if (dataLoading) {
        return <KanbanStatuscolumSkeleton />;
    }

    return (
        <div className="min-w-[300px] h-[calc(100vh-200px)] flex flex-col pl-3 border-l border-dashed border-[#b7b7b7]">
            <div className={`arrow-box arrow--1 py-3 ${statusClass} text-white`}>
                {status} ({loanList.filter((item) => item.status === status).length}) - {metaData.find((i) => i?.status === status)?.totalLoans}
            </div>
            <div className="text-center text-4xl font-light text-gray-400 py-5 flex items-center justify-center">
                {/* £{metaData.find(i => i?.status === status)?.totalRequiredAmount.toLocaleString("en-US")} */}£ {(localTotalAmountStat?.totalRequiredAmount ?? 0).toLocaleString("en-US")}
            </div>
            <div onDrop={(e) => adminData?.team !== "call-center" && dragDropHandler(e)} onDragOver={(e) => adminData?.team !== "call-center" && handleDragOver(e)} className="flex min-h-20 flex-grow flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto">
                {loanList
                    .filter((item) => item.status === status)
                    .map((i, index) => (
                        <div key={index} className={`rounded-lg bg-base-100 shadow-sm w-full ${i["updated"] === true && i["seenby"] && !i["seenby"].includes(user.email) ? "border-[3px] border-primary" : "border-[1px] border-base"} ${i["updated"] === true && !i["seenby"] && "border-[3px] border-primary "}`}>
                            {i?.["required-information"] && i?.["required-information"].some((item) => item?.uploaded === false) && <p className="text-sm text-white bg-red-600 rounded-t-lg px-2 py-1 text-center ml-auto">Required</p>}

                            <Link draggable onDragStart={(e) => adminData?.team !== "call-center" && dragStartHandler(e, i)} className="p-4 inline-block w-full rounded-b-none rounded-t-lg" to={`/admin/new-kanban/edit-deal/${i?._id}?lender=${i.lender}`}>
                                <h3 className="text-sm mb-3 font-bold">{i["business-name"]}</h3>
                                {showOfferAmountStatus.includes(status) && <p className="text-sm">Offer: {i["country"] + ((i["offer-amount"] && parseInt(i["offer-amount"] || 0).toLocaleString("en-US")) || 0)}</p>}
                                {!showOfferAmountStatus.includes(status) && <p className="text-sm">Require: {i["country"] + ((i["required-amount"] && parseInt(i["required-amount"]).toLocaleString("en-US")) || 0)}</p>}
                                {showOfferAmountStatus.includes(status) && <p className="text-sm">Funded: {i["country"] + ((i["offer-repayment"] && parseInt(i["offer-repayment"]).toLocaleString("en-US")) || 0)}</p>}
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-row items-center mt-2">
                                        <span className="text-gray-300 rounded-full text-xl mr-3">
                                            <FaUserCircle />
                                        </span>
                                        <span className="text-xs text-gray-500">{i["funding-manager"] || "Pending"}</span>
                                    </div>
                                    {/* <span className="bg-base-300 p-1 rounded text-xs">450k</span> */}
                                </div>
                                <div className="flex justify-between mt-2">
                                    <p className="text-xs text-gray-500 mt-2">
                                        <span className="bg-base-300 p-1 rounded mr-1">{i["funding-type"] || "New"}</span> {i.lender}
                                    </p>
                                    <p className="text-xs text-gray-500 mt-2">
                                        {new Date(i?.metadata?.createdAt).toLocaleDateString("en-UK", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                        })}
                                    </p>
                                </div>
                                {i?.callBack && (
                                    <div className="text-center text-white bg-red-700 py-2 mt-3 rounded-lg">
                                        <p className="text-xs">{new Date(i?.callBack?.date).toLocaleDateString("en-UK", { day: "numeric", month: "numeric", year: "numeric" }) + " , " + i?.callBack?.time}</p>
                                    </div>
                                )}
                            </Link>
                            <div className="grid grid-cols-2 text-sm p-2 border-t">
                                {i["business-phone"] && (
                                    <button className="flex justify-center items-center border-r py-1" onClick={() => copyText(i["business-phone"])}>
                                        <FaPhone />
                                    </button>
                                )}
                                {i["business-email"] && (
                                    <button className="flex justify-center items-center text-xl" onClick={() => copyText(i["business-email"])}>
                                        <PiEnvelopeSimpleBold />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                {loanList.filter((item) => item.status === status).length < metaData.find((i) => i?.status === status)?.totalLoans && (
                    <div ref={loaderRef} className="text-4xl my-3 text-centers">
                        <MoonLoader color="#e63a27" size={33} className="mx-auto" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatusColumn;
