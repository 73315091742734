import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";

import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { PacmanLoader } from "react-spinners";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import StatusColumn from "../../components/StatusColumn/StatusColumn";

const NewKanban = () => {
	const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
	const { adminData, user } = useContext(AuthContext);
	const { setFundedThisMonth, setStaffComs, setSearchRequestValue, searchRequestValue } = useContext(DataContext);

	const [loanList, setLoanList] = useState([]);
	const [metaData, setMetaData] = useState([]);

	const [loading, setLoading] = useState(false);

	const containerRef = useRef(null);

	useEffect(() => {
		axios
			.post(
				`${serverURL}/api/v3/new-monthly-funded-coms`,
				{
					email: user.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((response) => {
				return response.data;
			})
			.then((data) => {
				setFundedThisMonth(parseInt(data?.totalFundedAmount).toLocaleString("en-us") || 0);
				setStaffComs(data?.totalComs || 0);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const searchResult = (e) => {
		e.preventDefault();
		let tempObject = {};
		if (e.target["business-name"].value && e.target["business-name"].value !== null) {
			tempObject = {
				...tempObject,
				"business-name": e.target["business-name"].value,
			};
		}
		if (e.target["lender"].value && e.target["lender"].value !== "Lender") {
			tempObject = { ...tempObject, lender: e.target["lender"].value };
		}
		if ((adminData.role === "super-admin" || user.email === "millie@sedgefunding.co.uk" || adminData["access-level"] === "0") && e.target["funding-manager"].value && e.target["funding-manager"].value !== "Funding Manager") {
			tempObject = {
				...tempObject,
				"funding-manager": e.target["funding-manager"].value,
			};
		}
		if (e.target["category"].value && e.target["category"].value !== "Category") {
			tempObject = {
				...tempObject,
				category: e.target["category"].value,
			};
		}
		if ((adminData.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && e.target["team"].value && e.target["team"].value !== "Team") {
			console.log(e.target["team"].value);

			tempObject = { ...tempObject, team: e.target.team.value };
		}
		setSearchRequestValue(tempObject);
	};

	const handleDragOverk = (e) => {
		e.preventDefault();
		const container = containerRef.current;
		if (!container) return;

		const { clientX } = e;
		const { left, right } = container.getBoundingClientRect();

		if (clientX < left + 50) {
			container.scrollLeft -= 10; // Scroll left
		} else if (clientX > right - 50) {
			container.scrollLeft += 10; // Scroll right
		}
	};

	const removeSrarchFilters = () => {
		window.location.reload();
	};

	const reduceTotalLoansNumber = (statusName) => {
		const updatedMetaData = metaData.map((item) => {
			if (item?.status === statusName) {
				const updatedTotalLoans = item.totalLoans - 1;
				return { ...item, totalLoans: updatedTotalLoans };
			}
			return item;
		});

		setMetaData(updatedMetaData);
	};

	const increaseTotalLoansNumber = (statusName) => {
		const updatedMetaData = metaData.map((item) => {
			if (item?.status === statusName) {
				const updatedTotalLoans = item.totalLoans + 1;
				return { ...item, totalLoans: updatedTotalLoans };
			}
			return item;
		});

		setMetaData(updatedMetaData);
	};

	if (loading) {
		return (
			<div className="text-4xl mt-[35vh] flex justify-center items-center">
				<PacmanLoader color="#e63a27" />
			</div>
		);
	}

	return (
		<div>
			<form onSubmit={(e) => searchResult(e)} className="px-5 mb-5 w-full max-w-full p-3 mt-5 mr-10 bg-base-200 rounded-lg flex justify-between items-center ">
				<div className="flex items-center justify-center gap-2">
					<div className="flex gap-5">
						<input name="business-name" id="searchFilterName" placeholder="Search By Name...." type="text" className="input h-10 input-bordered  text-sm" defaultValue={searchRequestValue["business-name"] || null} />
						<select name="lender" id="searchFilterType" className="input input-bordered h-10 text-sm" defaultValue={searchRequestValue[""] || null}>
							<option value={searchRequestValue["lender"] || ""}>{searchRequestValue["lender"] || "Lender"}</option>
							<option value="New Deal">New Deal</option>
							<option value="YouLend">YouLend</option>
							<option value="Factored Finance">Factored Finance</option>
							<option value="365 Finance">365 Finance</option>
							<option value="IWOCA">IWOCA</option>
							<option value="Funding Circle">Funding Circle</option>
							<option value="Funding Alt">Funding Alt</option>
							<option value="Swiftfund">Swiftfund</option>
							<option value="Maxcap">Maxcap</option>
							<option value="Bizcap">Bizcap</option>
							<option value="Capify">Capify</option>
							<option value="Playter">Playter</option>
							<option value="Bibby">Bibby</option>
							<option value="Penny">Penny</option>
							<option value="Seneca">Seneca</option>
							<option value="Lloyds">Lloyds</option>
							<option value="Skipton">Skipton</option>
							<option value="Multifi">Multifi</option>
							<option value="Liberis">Liberis</option>
							<option value="Sedge MCA">Sedge MCA</option>
							<option value="Close Brothers">Close Brothers</option>
							<option value="Cube">Cube</option>
							<option value="Nucleus">Nucleus</option>
							<option value="Somo">Somo</option>
							<option value="Merchant Money">Merchant Money</option>
							<option value="Lenkie">Lenkie</option>
							<option value="Sapi">Sapi</option>
						</select>
						{(adminData.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && (
							<select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
								<option value={searchRequestValue["funding-manager"] || "Funding Manager"}>{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
								<option value="Pending">Pending</option>
								<option value="Wayne">Wayne</option>
								<option value="Peter">Peter</option>
								<option value="Smit">Smit</option>
								<option value="Jon">Jon</option>
								<option value="Millie">Millie</option>
								<option value="Mark">Mark</option>
								<option value="Becher">Becher</option>
								<option value="Don">Don</option>
								<option value="Chris">Chris</option>
								<option value="Maria">Maria</option>
								<option value="Lj">Lj</option>
								<option value="Charity">Charity</option>
								<option value="Carl">Carl</option>
								<option value="Janne">Janne</option>
								<option value="Khymir">Khymir</option>
								<option value="Sazzad">Sazzad</option>
								{(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Max">Max</option>}
								{(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Tan">Tan</option>}
								{(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Roger">Roger</option>}
								<option value="ON_HOLD">ON_HOLD</option>
							</select>
						)}
						{(user.email === "smit@readysteadyfinance.co.uk" || user.email === "charity@readysteadyfinance.co.uk") && (
							<select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
								<option value={searchRequestValue["funding-manager"] || "Funding Manager"}>{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
								<option value="Smit">Smit</option>
								<option value="Janne">Janne</option>
								<option value="Maria">Maria</option>
								<option value="Carl">Carl</option>
								<option value="Khymir">Khymir</option>
								<option value="Charity">Charity</option>
							</select>
						)}
						{(user.email === "jon@readysteadyfinance.co.uk" || user.email === "don@sedgefunding.co.uk") && (
							<select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
								<option value={searchRequestValue["funding-manager"] || "Funding Manager"}>{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
								<option value="Jon">Jon</option>
								<option value="Don">Don</option>
								<option value="Becher">Becher</option>
								<option value="Mark">Mark</option>
								<option value="Chris">Chris</option>
								<option value="Lj">Lj</option>
							</select>
						)}
						{(user.email === "mark@readysteadyfinance.co.uk" || user.email === "steven@sedgefunding.co.uk") && (
							<select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
								<option value={searchRequestValue["funding-manager"] || "Funding Manager"}>{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
								<option value="Steven">Steven</option>
								<option value="Mark">Mark</option>
								<option value="Carl">Carl</option>
							</select>
						)}
						<select name="category" id="searchFilterCategory" className="input input-bordered h-10 text-sm" defaultValue={searchRequestValue[""] || null}>
							<option value={searchRequestValue["category"] || ""}>{searchRequestValue["category"] || "Category"}</option>
							<option value="Restaurant">Restaurant</option>
							<option value="Hardware">Hardware</option>
							<option value="Footwear">Footwear</option>
							<option value="Beer and Wine">Beer and Wine</option>
							<option value="Car Parts">Car Parts</option>
							<option value="Sports">Sports</option>
							<option value="Convenient Store">Convenient Store</option>
							<option value="Haulage">Haulage</option>
							<option value="Retail">Retail</option>
							<option value="Hospitality">Hospitality</option>
						</select>
						{(adminData.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && (
							<select name="team" id="teamFilterSearch" className="input input-bordered h-10 text-sm" defaultValue={searchRequestValue[""] || null}>
								<option value={searchRequestValue["team"] || ""}>{searchRequestValue["team"] || "Team"}</option>
								<option value="Team Federation">Team Federation</option>
								<option value="Team Republic">Team Republic</option>
								<option value="Team Affiliates">Team Affiliates</option>
							</select>
						)}
					</div>
					<button type="button" onClick={removeSrarchFilters} className="text-xl hover:bg-base-100 p-3 rounded-lg">
						<IoCloseOutline />
					</button>
				</div>
				<div>
					<button type="submit" className="button mr-3">
						Search
					</button>
					<Link to={"/admin/add-single-deal"} className="button">
						Request Finance
					</Link>
					{/* {(adminData.role === "super-admin" || adminData.role === "admin") && <button type='button' onClick={addNewLead} className="button flex justify-center items-center ml-3 gap-1"><FaPlus /></button>} */}
				</div>
			</form>

			<div ref={containerRef} onDragOver={handleDragOverk} className="flex gap-3 overflow-x-auto">
				{/* Initial Review */}
				<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Initial Review"} loanList={loanList} />

				{/* Additional Documents */}
				{/* {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Additional Documents'} loanList={loanList} />} */}

				{/* Golden Data */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Golden Data"} loanList={loanList} />}

				{/* The Great Takeover */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"The Great Takeover"} loanList={loanList} />}

				{/* Pre-Underwriting */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Pre-Underwriting"} loanList={loanList} />}

				{/* Pre-Offer */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Pre-Offer"} loanList={loanList} />}

				{/* Underwriting */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Underwriting"} loanList={loanList} />}

				{/* Extra docs/ ob */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Extra docs ob"} loanList={loanList} />}

				{/* Awaiting Offer */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Awaiting Offer"} loanList={loanList} />}

				{/* Offer */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Offer"} loanList={loanList} />}

				{/* Contract Sent */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Contract Sent"} loanList={loanList} />}

				{/* Awaiting Funding */}
				{adminData?.team !== "call-center" && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Awaiting Funding"} loanList={loanList} />}

				{/* Funded This month */}
				<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Funded This Month"} loanList={loanList} />

				{/* Funded */}
				{(adminData?.role === "super-admin" || user.email === "smit@readysteadyfinance.co.uk" || user.email === "millie@sedgefunding.co.uk") && (
					<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Funded"} loanList={loanList} />
				)}

				{/* Last Chance */}
				<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Last Chance"} loanList={loanList} />

				{/* Cancelled */}
				{(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk" || user.email === "smit@readysteadyfinance.co.uk" || user.email === "max@swiftfund.co.uk") && (
					<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Cancelled"} loanList={loanList} />
				)}

				{/* Cancelled for ever */}
				{(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk" || user.email === "smit@readysteadyfinance.co.uk") && (
					<StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={"Cancelled for ever"} loanList={loanList} />
				)}

				{/* Qualified */}
				{/* {(adminData?.role === "super-admin" || adminData?.team === "call-center" || adminData?.team === "New Business Team") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Qualified'} loanList={loanList} />} */}

				{/* Non-Qualified */}
				{/* {(adminData?.role === "super-admin" || adminData?.team === "call-center" || adminData?.team === "New Business Team") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Non-Qualified'} loanList={loanList} />} */}
			</div>
		</div>
	);
};

export default NewKanban;
