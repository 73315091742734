import React, { useEffect, useState } from "react";
import { getDownloadURL } from "firebase/storage";
import ViewLoan from "../../components/ViewLoan/ViewLoan";
import EditLoan from "../../components/EditLoan/EditLoan";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import LoanPdfList from "../../components/LoanPdfList/LoanPdfList";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { IoMdSearch } from "react-icons/io";

const CanceledDeals = () => {
	const [loanList, setLoanList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortByDateState, setSortByDateState] = useState(false);
	const [sortByFundedAmount, setSortByFundedAmount] = useState(false);
	const [sortByRequiredAmount, setSortByRequiredAmount] = useState(false);
	const [searchRequestValue, setSearchRequestValue] = useState({});
	const [page, setPage] = useState(1); // Initialize page state
	const [limit, setLimit] = useState(100); // Initialize limit state
	const [totalPages, setTotalPages] = useState(1);

	const sortDataByDate = () => {
		setSortByDateState(true);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = new Date(a.createdDate);
			const dateB = new Date(b.createdDate);
			return dateA - dateB;
		});
		setLoanList(sortedData);
	};

	const sortDataByDateRevarse = () => {
		setSortByDateState(false);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = new Date(a.createdDate);
			const dateB = new Date(b.createdDate);
			return dateB - dateA;
		});
		setLoanList(sortedData);
	};

	const sortDataByFundedAmount = () => {
		setSortByFundedAmount(true);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = a["funded-amount"] || 0;
			const dateB = b["funded-amount"] || 0;
			return parseInt(dateA) - parseInt(dateB);
		});
		setLoanList(sortedData);
	};

	const sortDataByFundedAmountReverse = () => {
		setSortByFundedAmount(false);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = a["funded-amount"] || 0;
			const dateB = b["funded-amount"] || 0;
			return parseInt(dateB) - parseInt(dateA);
		});
		setLoanList(sortedData);
	};

	const sortDataByRequirements = () => {
		setSortByRequiredAmount(true);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = a["required-amount"] || 0;
			const dateB = b["required-amount"] || 0;
			return parseInt(dateA) - parseInt(dateB);
		});
		setLoanList(sortedData);
	};

	const sortDataByRequirementsReverse = () => {
		setSortByRequiredAmount(false);
		const sortedData = [...loanList].sort((a, b) => {
			const dateA = a["required-amount"] || 0;
			const dateB = b["required-amount"] || 0;
			return parseInt(dateB) - parseInt(dateA);
		});
		setLoanList(sortedData);
	};

	const updateSearchRequestValues = (e) => {
		setSearchRequestValue({
			...searchRequestValue,
			[e.target.name]: e.target.value,
		});
	};

	const updateNameSearchRequestValues = (e) => {
		e.preventDefault();
		// console.log(e.target["business-name"].value);
		setSearchRequestValue({
			...searchRequestValue,
			"business-name": e.target["business-name"].value,
		});
	};

	useEffect(() => {
		setLoanList([]);
		setLoading(true);
		// const url = `http://localhost:5000/cancelled-deals`;
		const url = `${process.env.REACT_APP_SERVER_URL}/cancelled-deals`;
		const requestBody = JSON.stringify({
			queryObj: searchRequestValue,
			page, // Include page in the request body
			limit, // Include limit in the request body
		});

		fetch(url, {
			method: "POST",
			body: requestBody,
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				const sortedData = [...data.loans].sort((a, b) => {
					const dateA = new Date(a.createdDate);
					const dateB = new Date(b.createdDate);
					return dateB - dateA;
				});
				setLoanList(sortedData);
				setTotalPages(data.totalPages);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, [searchRequestValue, page]);

	const rows = ["COMPANY NAME", "CREATED", "Req.", "Funded", "TYPE", "STATUS", "F. Manager", "ACTION"];

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	if (loading) {
		return (
			<div className="text-4xl mt-[35vh] flex  justify-center items-center">
				<PacmanLoader color="#e63a27" />
			</div>
		);
	}

	return (
		<div className="mt-5">
			<div className="flex justify-end items-center">
				<div>
					<button onClick={() => setSearchRequestValue({})} className="button mr-3">
						Clear Filter
					</button>
					{/* <Link to={'/admin/dashboard/request-loan'} className='button'>Request Finance</Link> */}
					<div className="join">
						<button className="join-item btn" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
							«
						</button>
						<button className="join-item btn">{`Page ${page}`}</button>
						<button className="join-item btn" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
							»
						</button>
					</div>
				</div>
			</div>
			<div className="mt-5 mb-20 rounded-lg bg-base-200">
				<div className="overflow-x-auto p-2">
					<table className="table p-5">
						<thead>
							<tr>
								{rows.map((item, i) => (
									<th key={i} scope="col">
										{item === "Funded" && item !== "CREATED" ? (
											sortByFundedAmount === false ? (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByFundedAmount} className="cursor-pointer">
														<BiSolidDownArrow />
													</span>
												</p>
											) : (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByFundedAmountReverse} className="cursor-pointer">
														<BiSolidUpArrow />
													</span>
												</p>
											)
										) : (
											item !== "CREATED" && item !== "Req." && item
										)}

										{item === "CREATED" &&
											(sortByDateState === false ? (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByDate} className="cursor-pointer">
														<BiSolidDownArrow />
													</span>
												</p>
											) : (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByDateRevarse} className="cursor-pointer">
														<BiSolidUpArrow />
													</span>
												</p>
											))}

										{item === "Req." &&
											(sortByRequiredAmount === false ? (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByRequirements} className="cursor-pointer">
														<BiSolidDownArrow />
													</span>
												</p>
											) : (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByRequirementsReverse} className="cursor-pointer">
														<BiSolidUpArrow />
													</span>
												</p>
											))}
									</th>
								))}
							</tr>
						</thead>
						<thead>
							<tr>
								{rows.map((item, i) => (
									<th key={i} scope="col">
										{item === "COMPANY NAME" && (
											<form onSubmit={(e) => updateNameSearchRequestValues(e)} className="flex">
												<input name="business-name" type="text" className="text-xs w-full border-2 p-2 font-normal" />
												<button type="submit" className="text-white text-xl ml-2 p-2 bg-primary rounded">
													<IoMdSearch />
												</button>
											</form>
										)}
										{item === "F. Manager" && (
											<select name="funding-manager" onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
												<option value="all" defaultValue>
													F. Manager
												</option>
												<option value="Pending">Pending</option>
												<option value="Wayne">Wayne</option>
												<option value="Peter">Peter</option>
												<option value="Smit">Smit</option>
												<option value="Dapo">Dapo</option>
												<option value="Jon">Jon</option>
												<option value="Millie">Millie</option>
												<option value="Tan">Tan</option>
												<option value="Don">Don</option>
												<option value="Mark">Mark</option>
												<option value="Myca">Myca</option>
												<option value="Chris">Chris</option>
												<option value="Maria">Maria</option>
												<option value="Lj">Lj</option>
												<option value="Charity">Charity</option>
											</select>
										)}
										{item === "TYPE" && (
											<select name="type" onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
												<option value="all" defaultValue>
													Type
												</option>
												<option value="Business Loan" defaultValue>
													Business Loan
												</option>
												<option value="Grants">Grants</option>
												<option value="MCA">MCA</option>
												{/* <option value="Invoice">Invoice</option> */}
												<option value="Renewal Loan">Renewal Loan</option>
												<option value="Renewal MCA">Renewal MCA</option>
											</select>
										)}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{loanList &&
								loanList.map((item, i) => (
									<tr key={i}>
										<td className="font-[500]">{item["business-name"]}</td>
										<td>
											{new Date(item?.createdDate).toLocaleDateString("en-UK", {
												day: "numeric",
												month: "numeric",
												year: "numeric",
											})}
										</td>
										<td>
											{item["country"]} {parseInt(item["required-amount"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}
										</td>
										<td>
											{item["country"]} {parseInt(item["funded-amount"]).toString() !== "NaN" ? parseInt(item["funded-amount"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}
										</td>
										<td>{item["type"]}</td>
										<td>{item["status"] || "Initial Review"}</td>
										<td>{item["funding-manager"] || "Pending"}</td>
										<td>
											<Link className="button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl" to={`/admin/dashboard/edit-loan/${item?._id}`}>
												<FaRegEye />
											</Link>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CanceledDeals;
