import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { HiTrash } from "react-icons/hi";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import EditBook from "../../components/EditBook/EditBook";
import ViewDeal from "../../components/ViewDeal/ViewDeal";
import axios from "axios";
import { IoCloseOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { PacmanLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import userplaceholder from "./../../assets/userplaceholder.png";

const Book = () => {
    const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
    const { user, adminData, createAccount, updateUserInfo } = useContext(AuthContext);
    const [dealList, setDealList] = useState([]);
    const [editBook, setEditBook] = useState({});
    const [totallRepayment, setTotallRepayment] = useState(0);
    const [refresh, setRefresh] = useState(true);
    const [viewDeal, setViewDeal] = useState({});
    const [bookFilter, setBookFilter] = useState({});
    const [sortDealbyData, setSortDealbyData] = useState(false);
    const [sortByLoanAmount, setSortByLoanAmount] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [showBook, setShowBook] = useState(true);
    const [showMonthly, setShowMonthly] = useState(false);
    const [showAccounts, setShowAccounts] = useState(false);

    // Monthly
    const [sortByDateState, setSortByDateState] = useState(false);
    const searchByMonthAndYear = (e) => {
        e.preventDefault();
        setDealList([]);
        const selectedMonth = e.target.month.value;
        const selectedYear = e.target.year.value;

        const url = `${process.env.REACT_APP_SERVER_URL}/deals/get-by-month-and-year/${selectedYear}/${selectedMonth}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setDealList(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    console.log(totallRepayment);

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_SERVER_URL}/deals/GETALL`)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setDealList(data);
    //             let tempTotall = 0;
    //             data.map((item) => {
    //                 tempTotall = tempTotall + parseInt(item.repayment);
    //             });
    //             setTotallRepayment(tempTotall);
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //         });
    // }, [refresh]);

    useEffect(() => {
        const fetchDeals = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/deals/GETALL`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setDealList(data);

                const totalRepayment = data.reduce((sum, item) => {
                    const value = parseInt(item.repayment, 10);
                    return sum + (isNaN(value) ? 0 : value);
                }, 0);
                
                setTotallRepayment(totalRepayment);
            } catch (error) {
                console.error("Error fetching deals:", error);
                // Handle error (e.g., show toast or set an error state)
            }
        };

        fetchDeals();
    }, [refresh]);

    // const sortDataByDate = () => {
    //     setSortByDateState(true);
    //     const sortedData = [...dealList].sort((a, b) => {
    //         const dateA = new Date(a.createdDate);
    //         const dateB = new Date(b.createdDate);
    //         return dateA - dateB;
    //     });
    //     setDealList(sortedData);
    // };
    const sortDataByDateRevarse = () => {
        setSortByDateState(false);
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        });
        setDealList(sortedData);
    };
    const rows = ["Funder", "Repayment", "Com Amo"];

    function separateByFunder(arr) {
        const funderArrays = {};

        arr.forEach((obj) => {
            const funder = obj.funder;

            if (!funderArrays[funder]) {
                funderArrays[funder] = [];
            }

            funderArrays[funder].push(obj);
        });

        return funderArrays;
    }

    function separateByCloser(arr) {
        const funderArrays = {};

        arr.forEach((obj) => {
            const closer = obj.closer;

            if (!funderArrays[closer]) {
                funderArrays[closer] = [];
            }

            funderArrays[closer].push(obj);
        });

        return funderArrays;
    }

    function getTotalRepayment(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return 0;
        }
        let totalRepayment = 0;

        arr.forEach((obj) => {
            const repayment = parseFloat(obj.repayment);
            if (!isNaN(repayment)) {
                totalRepayment += repayment;
            }
        });

        return totalRepayment;
    }

    function getTotalComamo(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return 0;
        }

        let totalComamo = 0;

        arr.forEach((obj) => {
            const comamo = parseFloat(obj.comamo);
            if (!isNaN(comamo)) {
                totalComamo += comamo;
            }
        });

        return totalComamo;
    }

    const separateByFunderresult = separateByFunder(dealList);
    const separateByCloserresult = separateByCloser(dealList);

    let totalRepaymentAcrossAll = 0;
    let totalComamoAcrossAll = 0;

    dealList.forEach((obj) => {
        const repayment = parseFloat(obj.repayment);
        const comamo = parseFloat(obj.comamo);

        if (!isNaN(repayment)) {
            totalRepaymentAcrossAll += repayment;
        }

        if (!isNaN(comamo)) {
            totalComamoAcrossAll += comamo;
        }
    });

    // ----------------------------

    // accounts

    const [users, setUsers] = useState([]);
    const [holidayData, setHolidayData] = useState([]);

    const accountsRows = ["Name", "Holidays", "Sick Days", "CREATED", "Role", "Email", "Phone", "Status", "Last Login", ""];

    const register = (event) => {
        event.preventDefault();
        const userInfo = event.target;
        const displayName = userInfo.name.value;
        const email = userInfo.email.value;
        const pass = userInfo.pass.value;
        const dialog = document.getElementById("my_modal_1");

        createAccount(email, pass)
            .then((res) => {
                // console.log(res.user.uid);
                updateUserInfo({ displayName })
                    .then((response) => {
                        console.log(res.user.uid);
                        fetch(`${process.env.REACT_APP_SERVER_URL}/update-user`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ displayName, email, pass, createdDate: new Date().toISOString() }),
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                console.log("User updated:", result);
                                // Handle the response or perform any necessary actions
                                dialog.close();
                                setUsers([...users, { displayName, email, pass, createdDate: new Date().toISOString() }]);
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                                // Handle the error or display an error message
                            });
                    })
                    .catch((error) => {
                        // An error occurred
                        // ...
                    });
                // console.log(user)
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage);
            });
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/user/GETALL`)
            .then((response) => response.json())
            .then((data) => {
                // Call a function to handle the loan data
                setUsers(data);
            })
            .catch((error) => {
                console.error("Error:", error);
                // Handle the error or display an error message
            });
        // Fetch the holiday data using Axios
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/holidays`)
            .then((response) => {
                // Set the holidayData state with the fetched data
                setHolidayData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching holiday data:", error);
                // Handle the error, display a message if needed
            });
    }, []);

    // ----------------------------

    const navigate = useNavigate();

    const submitDeals = (e) => {
        e.preventDefault();
        const dialog = document.getElementById("deal");
        const loanData = {
            funder: e?.target?.funder?.value,
            companyname: e?.target?.companyname?.value,
            loanamount: e?.target?.loanamount?.value,
            repayment: e?.target?.repayment?.value,
            factor: e?.target?.factor?.value,
            comrate: e?.target?.comrate?.value,
            comamo: e?.target?.comamo?.value,
            status: e?.target?.status?.value,
            closer: e?.target?.closer?.value,
            note: e?.target?.note?.value,
            user: user.email,
            createdDate: e?.target?.date?.value,
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/submit-deals`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(loanData),
        })
            .then((response) => response.json())
            .then((result) => {
                e.target.reset();
                loanData._id = result?.insertedId;
                setDealList([...dealList, loanData]);
                dialog.close();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
    };

    const openDeal = () => {
        window.deal.showModal();
    };

    const handleEditBook = (item) => {
        window.editBook.showModal();
        setEditBook(item);
    };

    const deleteRequest = async (id) => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/book-request/${id}`, {
                method: "DELETE",
            });
            setRefresh(!refresh); // Refresh the loan requests after deletion
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleViewRequest = (item) => {
        setViewDeal(item);
        window.viewDeal.showModal();
    };

    useEffect(() => {
        setIsloading(true);
        const userRole = adminData?.role;

        if (userRole === "super-admin") {
            axios
                .post(
                    `${serverURL}/super-admin/deals/GETALL`,
                    {
                        email: user.email,
                        searchQuery: bookFilter,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    setDealList(data);
                    let tempTotall = 0;
                    data.map((item) => {
                        tempTotall = tempTotall + parseInt(item.repayment);
                    });
                    setTotallRepayment(tempTotall);
                    setIsloading(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setIsloading(false);
                });
        }
    }, [refresh, bookFilter]);

    const sortDataByDate = () => {
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        });
        setSortDealbyData(false);
        setDealList(sortedData);
    };

    const sortDataByDateReverse = () => {
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        });
        setSortDealbyData(true);
        setDealList(sortedData);
    };
    const sortDataByLoanAmount = () => {
        setSortByLoanAmount(true);
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = a["loanamount"] || 0;
            const dateB = b["loanamount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        });
        setDealList(sortedData);
    };
    const sortDataByLoanAmountReverse = () => {
        setSortByLoanAmount(false);
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = a["loanamount"] || 0;
            const dateB = b["loanamount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        });
        setDealList(sortedData);
    };

    const searchResult = (e) => {
        e.preventDefault();
        let tempObject = {};
        if (e.target["companyname"].value && e.target["companyname"].value !== null) {
            tempObject = { ...tempObject, companyname: e.target["companyname"].value };
        }
        if (e.target["funder"].value && e.target["funder"].value !== "all") {
            tempObject = { ...tempObject, funder: e.target["funder"].value };
        }
        if (adminData.role === "super-admin" && e.target["status"].value && e.target["status"].value !== "all") {
            tempObject = { ...tempObject, status: e.target["status"].value };
        }
        if (adminData.role === "super-admin" && e.target["funding-manager"].value && e.target["funding-manager"].value !== "Pending") {
            tempObject = { ...tempObject, closer: e.target["funding-manager"].value };
        }
        setBookFilter(tempObject);
    };

    const bookRows = ["Date Funded", "Funder", "Company Name", "Loan Amount", "Repayment", "Factor", "Com Rate", "Com Amo", "Status", "Closer", "Action"];

    if (adminData?.role !== "super-admin") {
        navigate("/admin/new-kanban");
    }

    if (isLoading) {
        return (
            <div className="text-4xl mt-[35vh] flex justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div>
            <div className="flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl mt-6">
                <button onClick={() => (setShowBook(true), setShowAccounts(false), setShowMonthly(false))} className={`btn hover:bg-primary hover:text-white`}>
                    Book
                </button>
                <button onClick={() => (setShowBook(false), setShowAccounts(false), setShowMonthly(true))} className={`btn hover:bg-primary hover:text-white`}>
                    Monthly
                </button>
                <button onClick={() => (setShowBook(false), setShowAccounts(true), setShowMonthly(false))} className={`btn hover:bg-primary hover:text-white`}>
                    Accounts
                </button>
            </div>
            {showBook && (
                <div className="pb-20">
                    <form onSubmit={(e) => searchResult(e)} className="flex justify-between items-center sticky top-[60px] bg-base-100 z-10 py-5">
                        <div className="flex items-center justify-center gap-2">
                            <input name="companyname" type="text" placeholder="Search by Company Name" className="input h-10 input-bordered  text-sm" />
                            <select name="funder" className="input h-10 text-sm input-bordered">
                                <option value="all" selected>
                                    All Lenders
                                </option>
                                <option value="Liberis">Liberis</option>
                                <option value="Sedge MCA">Sedge MCA</option>
                                <option value="YouLend">YouLend</option>
                                <option value="Factored Finance">Factored Finance</option>
                                <option value="365 Finance">365 Finance</option>
                                <option value="Sedge">Sedge</option>
                                <option value="Capify">Capify</option>
                                <option value="Playter">Playter</option>
                                <option value="Penny">Penny</option>
                                <option value="IWOCA">IWOCA</option>
                                <option value="Swoop">Swoop</option>
                                <option value="Funding Alt">Funding Alt</option>
                                <option value="Maxcap">Maxcap</option>
                                <option value="Swiftfund">Swiftfund</option>
                                <option value="Funding Circle">Funding Circle</option>
                                <option value="My Cash Line">My Cash Line</option>
                                <option value="Bizcap">Bizcap</option>
                                <option value="Seneca">Seneca</option>
                                <option value="Sapi">Sapi</option>
                            </select>

                            <select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered">
                                <option value="Pending">F. Manager</option>
                                <option value="Wayne">Wayne</option>
                                <option value="Peter">Peter</option>
                                <option value="Smit">Smit</option>
                                <option value="Jon">Jon</option>
                                <option value="Millie">Millie</option>
                                <option value="Mark">Mark</option>
                                <option value="Becher">Becher</option>
                                <option value="Don">Don</option>
                                <option value="Carl">Carl</option>
                                <option value="Janne">Janne</option>
                                <option value="Khymir">Khymir</option>
                                <option value="Chris">Chris</option>
                                <option value="Maria">Maria</option>
                                <option value="Lj">Lj</option>
                                <option value="Charity">Charity</option>
                            </select>
                            <select name="status" className="input h-10 text-sm input-bordered">
                                <option value="all" selected>
                                    All Status
                                </option>
                                <option value="TBC">TBC</option>
                                <option value="Funded">Funded</option>
                                <option value="Renewed">Renewed</option>
                                <option value="Dead">Dead</option>
                                <option value="Moved">Moved</option>
                                <option value="Ready">Ready</option>
                            </select>
                            <button type="submit" className="bg-primary text-white py-2 px-4 text-sm rounded-lg">
                                Search
                            </button>
                            <button type="button" onClick={() => setBookFilter({})} className="text-xl hover:bg-base-100 p-3 rounded-lg">
                                <IoCloseOutline />
                            </button>
                        </div>

                        <h2 className="font-semibold text-3xl">Repayments: £{parseInt(totallRepayment).toString() !== "NaN" ? parseInt(totallRepayment).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"} </h2>
                        <button type="button" onClick={openDeal} className="bg-primary text-white py-2 px-4 rounded-lg">
                            <span className="flex items-center gap-3 text-sm">
                                Add Deal <FaPlus />
                            </span>
                        </button>
                    </form>
                    <div className="rounded-lg bg-base-200">
                        <div className="overflow-x-auto p-3">
                            <table className="table">
                                {/* head */}
                                <thead>
                                    <tr>
                                        {bookRows.map((item, i) => (
                                            <th key={i} scope="col">
                                                {item === "Date Funded" && item !== "Loan Amount" ? (
                                                    sortDealbyData ? (
                                                        <p className="flex justify-start items-center gap-2">
                                                            {item}{" "}
                                                            <span onClick={sortDataByDate} className="cursor-pointer">
                                                                <BiSolidDownArrow />
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <p className="flex justify-start items-center gap-2">
                                                            {item}{" "}
                                                            <span onClick={sortDataByDateReverse} className="cursor-pointer">
                                                                <BiSolidUpArrow />
                                                            </span>
                                                        </p>
                                                    )
                                                ) : (
                                                    item !== "CREATED" && item !== "Loan Amount" && item
                                                )}
                                                {item === "Loan Amount" &&
                                                    (sortByLoanAmount === false ? (
                                                        <p className="flex justify-start items-center gap-2">
                                                            {item}{" "}
                                                            <span onClick={sortDataByLoanAmount} className="cursor-pointer">
                                                                <BiSolidDownArrow />
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <p className="flex justify-start items-center gap-2">
                                                            {item}{" "}
                                                            <span onClick={sortDataByLoanAmountReverse} className="cursor-pointer">
                                                                <BiSolidUpArrow />
                                                            </span>
                                                        </p>
                                                    ))}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // console.log(dealList)
                                        dealList.length !== 0 &&
                                            dealList.map((item, i) => (
                                                <tr className="border-b-2 border-base-300" key={i}>
                                                    <td>
                                                        {new Date(item?.createdDate).toLocaleDateString("en-UK", {
                                                            day: "numeric",
                                                            month: "numeric",
                                                            year: "numeric",
                                                        })}
                                                    </td>
                                                    <td>{item["funder"]}</td>
                                                    <td className="w-[300px]">{item["companyname"]}</td>
                                                    <td>£ {parseInt(item["loanamount"]).toString() !== "NaN" ? parseInt(item["loanamount"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
                                                    <td>£ {parseInt(item["repayment"]).toString() !== "NaN" ? parseInt(item["repayment"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
                                                    <td>{item["factor"]}</td>
                                                    <td>{item["comrate"]}</td>
                                                    <td>£ {parseInt(item["comamo"]).toString() !== "NaN" ? parseInt(item["comamo"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
                                                    <td>{item["status"]}</td>
                                                    <td>{item["closer"]}</td>

                                                    <td className="flex items-center">
                                                        <button onClick={() => handleEditBook(item)} className="btn btn-xs">
                                                            EDIT
                                                        </button>
                                                        <button onClick={() => handleViewRequest(item)} className="btn btn-xs ml-3">
                                                            VIEW
                                                        </button>
                                                        <span onClick={() => deleteRequest(item?._id)} className="ml-4 cursor-pointer text-[#e63a27] text-2xl">
                                                            <HiTrash></HiTrash>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <dialog id="viewDeal" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                        <form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                            <ViewDeal item={viewDeal} />
                            <div className="mt-3 text-right">
                                {/* if there is a button in form, it will close the modal */}
                                <button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                            </div>
                        </form>
                    </dialog>
                    <dialog id="editBook" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                        <EditBook item={editBook} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} setDealList={setDealList} dealList={dealList} />
                    </dialog>
                    <dialog id="deal" className="modal w-[900px] max-w-[900px] mx-auto">
                        <form onSubmit={(e) => submitDeals(e)} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] rounded-lg">
                            <div className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col">
                                    <label className="lebel">Funder:</label>
                                    <select name="funder" className="input border-gray-300" required>
                                        <option value={null} defaultValue>
                                            Funder
                                        </option>
                                        <option value="Liberis">Liberis</option>
                                        <option value="Sedge MCA">Sedge MCA</option>
                                        <option value="YouLend">YouLend</option>
                                        <option value="Factored Finance">Factored Finance</option>
                                        <option value="365 Finance">365 Finance</option>
                                        <option value="Sedge">Sedge</option>
                                        <option value="Capify">Capify</option>
                                        <option value="Playter">Playter</option>
                                        <option value="Penny">Penny</option>
                                        <option value="IWOCA">IWOCA</option>
                                        <option value="Swoop">Swoop</option>
                                        <option value="Funding Alt">Funding Alt</option>
                                        <option value="Seneca">Seneca</option>
                                        <option value="Maxcap">Maxcap</option>
                                        <option value="Swiftfund">Swiftfund</option>
                                        <option value="Funding Circle">Funding Circle</option>
                                        <option value="Bizcap">Bizcap</option>
                                        <option value="Sapi">Sapi</option>
                                    </select>
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Company Name:</label>
                                    <input name="companyname" type="text" placeholder="Company Name" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Loan Amount:</label>
                                    <input name="loanamount" type="number" placeholder="Loan Amount" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Repayment:</label>
                                    <input name="repayment" type="number" placeholder="Repayment" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Factor:</label>
                                    <input name="factor" type="text" placeholder="Factor" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Com Rate:</label>
                                    <input name="comrate" type="text" placeholder="Com Rate" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Com Amo:</label>
                                    <input name="comamo" type="number" placeholder="Com Amo" className="input border-gray-300" required />
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Status:</label>
                                    <select name="status" className="input border-gray-300" required>
                                        <option value={null} defaultValue>
                                            Status
                                        </option>
                                        <option value="TBC">TBC</option>
                                        <option value="Funded">Funded</option>
                                        <option value="Renewed">Renewed</option>
                                        <option value="Dead">Dead</option>
                                        <option value="Moved and Ready">Moved and Ready</option>
                                    </select>
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Closer:</label>
                                    <select name="closer" className="input border-gray-300" required>
                                        <option value={null} defaultValue>
                                            Closer
                                        </option>
                                        <option value="Wayne">Wayne</option>
                                        <option value="Jon">Jon</option>
                                        <option value="Milly">Millie</option>
                                        <option value="Smit">Smit</option>
                                        <option value="Ver">Virgil</option>
                                        <option value="Dapo">Dapo</option>
                                        <option value="Don">Don</option>
                                        <option value="Chris">Chris</option>
                                        <option value="Maria">Maria</option>
                                        <option value="Lj">Lj</option>
                                        <option value="Charity">Charity</option>
                                        <option value="Mark">Mark</option>
                                        <option value="Myca">Myca</option>
                                        <option value="Reggie">Reggie</option>
                                        <option value="Tracy">Tracy</option>
                                        <option value="Support">Support</option>
                                        <option value="New Business">New Business</option>
                                        <option value="Jay">Jay</option>
                                        <option value="Lou">Lou</option>
                                        <option value="Juniel">Juniel</option>
                                        <option value="Paul">Paul</option>
                                        <option value="Josh">Josh</option>
                                        <option value="Aidan">Aidan</option>
                                    </select>
                                </div>
                                <div className="flex flex-col">
                                    <label className="lebel">Date:</label>
                                    <input name="date" type="date" placeholder="Date" className="input w-full border-gray-300" required />
                                </div>
                            </div>
                            <div className="flex flex-col mt-3">
                                <label className="lebel">Note:</label>
                                <input name="note" type="text" placeholder="Note" className="input  w-full border-gray-300" />
                            </div>
                            <div className="flex gap-3 mt-4">
                                <button type="button" onClick={() => closeModal("deal")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">
                                    Close
                                </button>
                                <button type="submit" className="button basis-1/2 !rounded">
                                    submit
                                </button>
                            </div>
                        </form>
                    </dialog>
                </div>
            )}

            {showMonthly && (
                <div className="mt-10 mr-10 pb-20">
                    <div className="flex items-center">
                        <form onSubmit={searchByMonthAndYear}>
                            <select name="month" className="input border-gray-300 mr-3 w-auto">
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select name="year" className="input border-gray-300 mr-3 w-auto">
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                            </select>
                            <button type="submit" className="button">
                                Search
                            </button>
                        </form>
                    </div>
                    <div className="flex gap-10">
                        <div className="mt-5 basis-1/2">
                            <p className="mb-2 text-2xl">MCA:</p>
                            <div className="overflow-x-auto p-3 mb-5 rounded-lg bg-base-200">
                                <table className="table">
                                    {/* head */}
                                    <thead>
                                        <tr>
                                            {rows.map((item, i) => (
                                                <th key={i} scope="col">
                                                    {item === "Date Funded" ? (
                                                        sortByDateState === false ? (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDate} className="cursor-pointer">
                                                                    <BiSolidDownArrow />
                                                                </span>
                                                            </p>
                                                        ) : (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                                    <BiSolidUpArrow />
                                                                </span>
                                                            </p>
                                                        )
                                                    ) : (
                                                        item
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>365 Finance</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["365 Finance"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["365 Finance"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Liberis</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Liberis"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Liberis"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>YouLend</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="mb-2 text-2xl">Business Loans:</p>
                            <div className="overflow-x-auto p-3 rounded-lg bg-base-200">
                                <table className="table">
                                    {/* head */}
                                    <thead>
                                        <tr>
                                            {rows.map((item, i) => (
                                                <th key={i} scope="col">
                                                    {item === "Date Funded" ? (
                                                        sortByDateState === false ? (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDate} className="cursor-pointer">
                                                                    <BiSolidDownArrow />
                                                                </span>
                                                            </p>
                                                        ) : (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                                    <BiSolidUpArrow />
                                                                </span>
                                                            </p>
                                                        )
                                                    ) : (
                                                        item
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Capify</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Capify"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Capify"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Funder</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funder"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funder"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Sedge</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Sedge"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Sedge"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Swoop</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Seneca</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Seneca"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Seneca"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Maxcap</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Maxcap"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Maxcap"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Swiftfund</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Swiftfund"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Swiftfund"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Funding Circle</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funding Circle"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funding Circle"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Funding Alt</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funding Alt"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funding Alt"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>IWOCA</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByFunderresult["IWOCA"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByFunderresult["IWOCA"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        {/* <tr>
                                                    <td className='font-bold'>
                                                        Total
                                                    </td>
                                                    <td >
                                                        £ {totalRepaymentAcrossAll.toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                                    </td>
                                                    <td >
                                                        £ {totalComamoAcrossAll.toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                                    </td>
                                                </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mt-5 rounded-lg bg-base-200 basis-1/2">
                            <div className="overflow-x-auto p-3">
                                <table className="table">
                                    {/* head */}
                                    <thead>
                                        <tr>
                                            {rows.map((item, i) => (
                                                <th key={i} scope="col">
                                                    {item === "Date Funded" ? (
                                                        sortByDateState === false ? (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDate} className="cursor-pointer">
                                                                    <BiSolidDownArrow />
                                                                </span>
                                                            </p>
                                                        ) : (
                                                            <p className="flex justify-start items-center gap-2">
                                                                {item}{" "}
                                                                <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                                    <BiSolidUpArrow />
                                                                </span>
                                                            </p>
                                                        )
                                                    ) : (
                                                        item
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>wayne</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Wayne"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Wayne"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>jon</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Jon"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Jon"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>millie</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Milly"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Milly"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>smit</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Smit"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Smit"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>virgil</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Ver"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Ver"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>Mark</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Mark"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Mark"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>support</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>new business</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>jay</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>lou</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>juniel</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>paul</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>josh</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>aidan</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td>TBC</td>
                                            <td>£ {parseInt(getTotalRepayment(separateByCloserresult["TBC"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {parseInt(getTotalComamo(separateByCloserresult["TBC"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-bold">Totall</td>
                                            <td>£ {totalRepaymentAcrossAll.toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                            <td>£ {totalComamoAcrossAll.toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showAccounts && (
                <div className="mt-5 mx-auto">
                    <div className="flex justify-between items-center ">
                        {/* <input onChange={(e) => searchByName(e)} name='search' type="text" placeholder="Search" className="input w-72" /> */}
                        <p className="text-3xl font-semibold">Accounts</p>
                        <div>
                            <button onClick={() => window.my_modal_1.showModal()} className="button">
                                <FaPlus />
                                Add
                            </button>
                        </div>
                    </div>
                    <div className="mt-5 max-h-[calc(100vh-184px)] overflow-y-auto border border-gray-400 rounded-xl relative">
                        <table className="table relative block ">
                            {/* head */}
                            <thead className="sticky top-0 bg-base-300">
                                <tr className="px-10">
                                    {accountsRows.map((item, i) => (
                                        <th key={i} scope="col" className="uppercase first:pl-10 py-5">
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((item, i) => (
                                    <tr key={i}>
                                        <th scope="row" className="pl-10 flex justify-start items-center gap-3">
                                            <img className="w-8 rounded-full" src={userplaceholder} alt="" />
                                            {item["displayName"]}
                                        </th>
                                        <td>{holidayData.map((singleData) => singleData?.name === item["displayName"] && singleData?.totalHolidays + (singleData?.totalHalfDays / 2 || 0))}</td>
                                        <td>{holidayData.map((singleData) => singleData?.name === item["displayName"] && singleData?.totalSickDays)}</td>
                                        <td>
                                            {new Date(item?.createdDate).toLocaleDateString("en-UK", {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                            })}
                                        </td>
                                        <td>{item["role"]}</td>
                                        <td>{item["email"] || "Pending"}</td>
                                        <td>{item["phone"]}</td>
                                        <td>Status</td>
                                        <td>10.56 AM, 14/04/2024</td>
                                        <td>
                                            <button onClick={() => navigate(`/admin/users/${item?._id}`)} className="btn text-xl">
                                                <PiPencilSimpleDuotone />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <dialog id="my_modal_1" className="modal w-[400px] mx-auto rounded">
                        <form onSubmit={register} method="dialog" className="modal-box">
                            <label className="label text-sm" htmlFor="introducedBy">
                                Introduced By:
                            </label>
                            <input id="introducedBy" className="w-full input input-sm input-bordered" type="text" />
                            <label className="label text-sm" htmlFor="name">
                                Name:
                            </label>
                            <input id="name" className="w-full input input-sm input-bordered" placeholder="Type User Name / Company Here" type="text" />
                            <label className="label text-sm" htmlFor="email">
                                Email:
                            </label>
                            <input type="email" id="email" className="w-full input input-sm input-bordered" placeholder="Type User Email" />
                            <label className="label text-sm" htmlFor="pass">
                                Password:
                            </label>
                            <input type="password" id="pass" className="w-full input input-sm input-bordered" placeholder="Type User Password" />
                            <div className="modal-action">
                                {/* if there is a button in form, it will close the modal */}
                                <button type="submit" className="btn btn-sm text-xs bg-rsf text-[#fff]">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </dialog>
                </div>
            )}
        </div>
    );
};

export default Book;
