import React, { useContext, useEffect, useState } from "react";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../../firebase/firebase.config";
import ViewLoan from "../../components/ViewLoan/ViewLoan";
import EditLoan from "../../components/EditLoan/EditLoan";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import LoanPdfList from "../../components/LoanPdfList/LoanPdfList";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { IoMdSearch } from "react-icons/io";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const CallBackList = () => {
    const { adminData, user } = useContext(AuthContext);
    const [loanList, setLoanList] = useState([]);
    const [viewLoan, setViewLoan] = useState({});
    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pdflist, setPdflist] = useState([]);
    const [searchRequestValue, setSearchRequestValue] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [businessName, setBusinessName] = useState();
    const [fundingManager, setFundingManager] = useState();

    useEffect(() => {
        loadData();
    }, [user, adminData, searchRequestValue]);

    const loadData = async (searchQuery = {}) => {
        setLoading(true);
        try {
            const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("token")}` } };
            const body = { email: user?.email, searchQuery };
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v3/deals/call-back-list`, body, config);

            setLoanList(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response) {
                console.error("Error fetching deals:", error.response.data.message);
            } else {
                console.error("Error fetching deals:", error.message);
            }
        }
    };

    function convertTextToDate(text) {
        const [datePart, timePart, am] = text.split(" ");
        const [day, month, year] = datePart.split("-");

        const [hours, minutes] = timePart.split(":");
        const isAM = am === "AM";

        let hourNum = parseInt(hours, 10);
        if (hourNum === 12) {
            hourNum = isAM ? 0 : 12;
        } else {
            hourNum += isAM ? 0 : 12;
        }

        const date = new Date(year, month - 1, day, hourNum, minutes);

        return date;
    }

    const sortByCallBackDateAndTime = (array) => {
        return array.sort((a, b) => {
            const dateTimeA = convertTextToDate(a.callBack.date + " " + a.callBack.time);
            const dateTimeB = convertTextToDate(b.callBack.date + " " + b.callBack.time);

            if (dateTimeA < dateTimeB) {
                return -1;
            }
            if (dateTimeA > dateTimeB) {
                return 1;
            }
            return 0;
        });
    };

    sortByCallBackDateAndTime(loanList);

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
        setEditLoan({});
    };

    const handlePdfLinkClick = (e, item) => {
        e.preventDefault();

        getDownloadURL(item)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch((error) => {
                console.error("Error getting download URL:", error);
            });
    };

    const rows = ["COMPANY NAME", "F. Manager", "Call Back", "ACTION"];

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const deleteCallBackEntry = async (id) => {
        setLoading(true);
        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/update-callback/${id}`,
            { email: user?.email },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        setLoanList(loanList.filter((item) => item._id !== id));
        setLoading(false);
    };

    const handleSearch = () => {
        const queryObj = {};

        if (businessName) queryObj["business-name"] = businessName;
        if (fundingManager) queryObj["funding-manager"] = fundingManager;

        loadData(queryObj);
    };

    if (loading) {
        return (
            <div className="text-4xl mt-[35vh] flex  justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center">
                <div>
                    <input type="text" className="input h-10 input-bordered text-sm mr-2" placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    {(adminData?.role === "super-admin") &&
                    (
                        <select className="input h-10 input-bordered text-sm mr-2" onChange={(e) => setFundingManager(e.target.value)} value={fundingManager}>
                            <option>Select</option>
                            <option value="Wayne">Wayne</option>
                            <option value="Peter">Peter</option>
                            <option value="Smit">Smit</option>
                            <option value="Jon">Jon</option>
                            <option value="Millie">Millie</option>
                            <option value="Mark">Mark</option>
                            <option value="Becher">Becher</option>
                            <option value="Don">Don</option>
                            <option value="Carl">Carl</option>
                            <option value="Janne">Janne</option>
                            <option value="Khymir">Khymir</option>
                            <option value="Chris">Chris</option>
                            <option value="Maria">Maria</option>
                            <option value="Lj">Lj</option>
                            <option value="Charity">Charity</option>
                            <option value="ON_HOLD">ON_HOLD</option>
                        </select>
                    )}
                    <button onClick={handleSearch} className="button">
                        Search
                    </button>
                </div>
                <div>
                    <button onClick={() => (loadData(), setBusinessName(null), setFundingManager(null))} className="button mr-3">
                        Clear Filter
                    </button>
                    {/* <Link to={'/admin/dashboard/request-loan'} className='button'>Request Finance</Link> */}
                    <div className="join">
                        <button className="join-item btn" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                            «
                        </button>
                        <button className="join-item btn">{`Page ${page}`}</button>
                        <button className="join-item btn" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                            »
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-5 mb-20 rounded-lg bg-base-200">
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {rows.map((item, i) => (
                                    <th key={i} scope="col">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => (
                                    <tr key={i}>
                                        <td className="font-[500]">{item["business-name"]}</td>
                                        <td>{item["funding-manager"] || "Pending"}</td>
                                        <td>
                                            <p>{new Date(item?.callBack?.date).toLocaleDateString("en-UK", { day: "numeric", month: "numeric", year: "numeric" }) + " ," + item?.callBack?.time}</p>
                                        </td>
                                        <td>
                                            <button onClick={() => deleteCallBackEntry(item?._id)} className="button mr-3">
                                                Complete
                                            </button>
                                            <Link className="button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl" to={`/admin/new-kanban/edit-deal/${item?._id}`}>
                                                <FaRegEye />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* popup */}
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoan item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
            <dialog id="loanPdfList" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[500px] max-w-[500px] p-10">
                    <LoanPdfList pdflist={pdflist} handlePdfLinkClick={handlePdfLinkClick} />
                    <button type="button" onClick={() => closeModal("loanPdfList")} className="mt-3 button !text-[#000] !bg-[#f2f2f2] !rounded">
                        Close
                    </button>
                </div>
            </dialog>
            <dialog id="viewLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <ViewLoan item={viewLoan} />
                    <div className="mt-3 text-right">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                    </div>
                </form>
            </dialog>
        </div>
    );
};

export default CallBackList;
