import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import dashboardIcon from "../../assets/dashboard.png";
import taskIcon from "../../assets/tasks.png";
import starIcon from "../../assets/stars.png";
import userIcon from "../../assets/user.png";
import logo from "../../assets/image.png";
import deletePng from "../../assets/delete.png";
import giftcard from "../../assets/giftcard.png";
import database from "../../assets/database.png";
import phone from "../../assets/iphone.png";
import graph from "../../assets/graph.png";
import book from "../../assets/book.png";
import monthlyicon from "../../assets/calendar.png";
import helpicon from "../../assets/question.png";
import invoice from "../../assets/invoice.png";
import { AuthContext } from "../../context/AuthContext";

function Sidenav() {
    const { adminData, user } = useContext(AuthContext);
    return (
        <aside className="w-full max-w-[270px] min-w-[270px] h-screen overflow-auto p-6 sticky top-0">
            <p className="text-xl font-semibold">Ready Steady Portal</p>
            <div className="divider"></div>
            <div>
                <div>
                    <NavLink to="/admin/new-kanban" className="sidenav-btn hover:bg-base-200 animatedButton">
                        <div className="w-7">
                            <img src={taskIcon} className="h-6" alt="Home icon" />
                        </div>
                        <span className="font-[600] text-[14px]">New Kanban</span>
                        <img src={starIcon} className="h-6" alt="Home icon" />
                    </NavLink>
                    {/* {adminData?.role === "super-admin" && (
                        <NavLink to="/admin/kanaban" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={dashboardIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Old Kanban</span>
                        </NavLink>
                    )} */}
                    {(adminData?.role === "super-admin" || adminData?.name === "Millie") && (
                        <NavLink to="/admin/new-dashboard" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={dashboardIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">New Dashboard</span>
                        </NavLink>
                    )}
                    {user?.email !== "max@swiftfund.co.uk" && (
                        <NavLink to="/admin/callbacklist" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={phone} className="h-7 w-5" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Call Back List</span>
                        </NavLink>
                    )}
                    {/* {
                        user.email === 'szdhos2@gmail.com' && <>
                            <NavLink to='/admin/all-open-bank' className='sidenav-btn hover:bg-base-200'>
                                <div className='w-7'>
                                    <img src={dashboardIcon} className='h-6' alt="Dashboard icon" />
                                </div>
                                <span className='font-[600] text-[14px]'>Open Banking</span>
                            </NavLink>
                        </>
                    } */}
                    {/* {
                        user.email === 'szdhos2@gmail.com' && <>
                            <NavLink to='/admin/add-user' className='sidenav-btn hover:bg-base-200'>
                                <div className='w-7'>
                                    <img src={userIcon} className='h-6' alt="Dashboard icon" />
                                </div>
                                <span className='font-[600] text-[14px]'>Add User</span>
                            </NavLink>
                        </>
                    }
                    
                    {
                        user.email === 'szdhos2@gmail.com' && <>
                            <NavLink to='/admin/add-user' className='sidenav-btn hover:bg-base-200'>
                                <div className='w-7'>
                                    <img src={userIcon} className='h-6' alt="Dashboard icon" />
                                </div>
                                <span className='font-[600] text-[14px]'>Edit User</span>
                            </NavLink>
                        </>
                    } */}
                    {/* {
                        user.email === 'szdhos2@gmail.com' && <NavLink to='/admin/all-status' className='sidenav-btn hover:bg-base-200'>
                            <div className='w-7'>
                                <img src={dashboardIcon} className='h-6' alt="Dashboard icon" />
                            </div>
                            <span className='font-[600] text-[14px]'>All Status</span>
                        </NavLink>
                    } */}
                    {/* {adminData?.role === "super-admin" && (
                        <NavLink to="/admin/dashboard" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={dashboardIcon} className="h-6" alt="Dashboard icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Dashboard</span>
                        </NavLink>
                    )} */}
                    {/* {
                        (adminData?.role === 'super-admin' || user?.email === 'millie@sedgefunding.co.uk') ? <NavLink to='/admin/cancelled-deals' className='sidenav-btn hover:bg-base-200'>
                            <div className='w-7'>
                                <img src={dashboardIcon} className='h-6' alt="Dashboard icon" />
                            </div>
                            <span className='font-[600] text-[14px]'>Cancelled Deals</span>
                        </NavLink> : <></>
                    } */}
                    {adminData?.role === "super-admin" ? (
                        <NavLink to="/admin/invoice-customer" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={invoice} className="h-6" alt="Dashboard icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Invoice Customers</span>
                        </NavLink>
                    ) : (
                        <></>
                    )}
                    {/* {adminData?.role === "super-admin" && (
                        <NavLink to="/admin/user" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={userIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Accounts</span>
                        </NavLink>
                    )} */}
                    {(adminData?.role === "super-admin" || user?.email === "smit@readysteadyfinance.co.uk" || user?.email === "jon@readysteadyfinance.co.uk" || user?.email === "steven@sedgefunding.co.uk") && (
                        <NavLink to="/admin/staff-review" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={userIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Staff Review</span>
                        </NavLink>
                    )}
                    {adminData?.role !== "super-admin" && user?.email !== "max@swiftfund.co.uk" ? (
                        <NavLink to={`/admin/single-staff-review/${adminData.name}`} className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={userIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">My Review</span>
                        </NavLink>
                    ) : (
                        <></>
                    )}
                    {adminData?.role === "super-admin" ? (
                        <NavLink to="/admin/book" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                            <img src={userIcon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Admin</span>
                        </NavLink>
                    ) : (
                        <></>
                    )}
                    {/* {adminData?.role === "super-admin" && (
                        <NavLink to="/admin/monthly" className="sidenav-btn hover:bg-base-200">
                            <div className="w-6">
                                <img src={graph} className="h-7" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Monthly</span>
                        </NavLink>
                    )} */}
                    {user?.email !== "max@swiftfund.co.uk" && (
                        <NavLink to="/admin/calender" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={monthlyicon} className="h-6" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Calender</span>
                        </NavLink>
                    )}
                    {adminData?.role === "super-admin" && (
                        <NavLink to="/admin/qa-bonus" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={giftcard} className="h-7" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">QA</span>
                        </NavLink>
                    )}

                    {/* {adminData?.role !== "super-admin" && (
                        <NavLink to="/admin/qa-bonus" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={giftcard} className="h-7" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Daily Calls</span>
                        </NavLink>
                    )} */}

                    {/* {adminData?.role === "super-admin" ? (
                        <NavLink to="/admin/trash" className="sidenav-btn hover:bg-base-200">
                            <div className="w-7">
                                <img src={deletePng} className="h-7" alt="Home icon" />
                            </div>
                            <span className="font-[600] text-[14px]">Trash</span>
                        </NavLink>
                    ) : (
                        <></>
                    )} */}
                    <NavLink to="/admin/company-info" className="sidenav-btn hover:bg-base-200">
                        <div className="w-7">
                            <img src={helpicon} className="h-7" alt="Home icon" />
                        </div>
                        <span className="font-[600] text-[14px]">Help</span>
                    </NavLink>
                    {/* <NavLink to='/admin/update-profile' className='sidenav-btn'>
                        <ImProfile size={25} style={{ color: "#e63a27" }} />
                        <span className='text-[#344767] font-[600] text-[14px]'>Update Profile</span>
                    </NavLink> */}
                    {/* {`
                        user.photoURL && <NavLink to='/admin/chat-room' className='sidenav-btn'>
                        <BsFillChatDotsFill size={25} style={{ color: "#e63a27" }} />
                        <span className='text-[#344767] font-[600] text-[14px]'>Chat Room</span>
                    </NavLink>
                    } */}

                    {/* <div className='flex items-center gap-3 p-3 bg-[#fff] rounded-lg mb-4' style={{boxShadow:"0 20px 27px 0 rgba(0, 0, 0, 0.05)"}}>
            <div className='bg-rsf p-2 rounded-lg'>
              <span className='text-[#fff]'><BsShop className=''/></span>
            </div>
            <span className='text-[#344767] font-[600] text-[14px]'>Dashboard</span>
          </div> */}
                </div>
            </div>
        </aside>
    );
}

export default Sidenav;
